<template>
    <v-app>
        <v-main >    
            <TopHeader />
            <router-view />
            <footerComponent />

        </v-main>
    </v-app>
</template>
<script>
    //import Description from "./components/Description";
  //import Images from "./components/Images";
import TopHeader from './components/TopHeader';
import footerComponent from './components/footerComponent';
export default {
    name: 'App',
    components: {
        TopHeader,
        footerComponent,
       // Description,
     // Images
    },

    data: () => ({
        //
    }),
};
</script>

<style type="text/css">
.v-main { overflow-x: hidden; }
.overlay-image:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index:1
}
.image-border-radius{border-radius: 10%}
button{text-transform: unset !important;}
.page-title{color: #1B63F8 !important; font-size: 14px; font-weight: 700; letter-spacing: 3px; padding:2% 0;}
.page-sub-title h1{ margin-bottom: 15px; font-size: 40px;  }
.page-sub-title h2{ margin-bottom: 15px; font-size: 38px;  }
.myslider{z-index: 0}
.myslider .v-carousel__controls{bottom:10% !important;}
.mytabs .v-item-group{margin-bottom: 40px;}
.vertical-tab .v-tabs-bar{background:none !important;}
.vertical-tab .v-tab{justify-content: left !important; color: rgba(255, 255, 255, 0.5)!important; margin: 10px 0}
.vertical-tab .v-tabs-items{width:  35% !important; border-radius: 30px !important; position: absolute!important; right: 0; top: 15%; padding:2%;}
.v-tabs--vertical > .v-tabs-bar .v-tab{font-size: 40px; font-weight: 500}
.v-tabs--vertical .v-list-item__title{font-size: 20px !important;font-weight:700; margin-top: 10px; margin-bottom: 10px; line-height: auto !important; }
.v-tabs--vertical .v-list-item__subtitle{font-size:16px !important;}
.v-tabs--vertical .v-list-item {margin-bottom: 10px;}
.vertical-tab-title .mt-10{margin: 20px 0}
.vertical-tab{margin-top:  50px !important;}
.vertical-tab .v-tab--active{font-size: 40px !important; color: rgba(255, 255, 255, 1)!important}
.vertical-tab .v-tab--active .v-icon{font-size: 28px !important; color: rgba(255, 255, 255, 1)!important}
.vertical-tab .v-icon{margin-right: 20px; color: rgba(255, 255, 255, 0.5)!important}

.vertical-tab-title{
    .text-h3{font-size: 52px !important; font-weight: 700; }
    .font-weight-bold{letter-spacing: 3px; font-size: 14px;}
}
.vertical-tab .v-tab--active{font-size: }

.gradient-button{background: linear-gradient(90deg, #00C6FF 0%, #0072FF 100%);}
.gradient-button a{color: #fff !important;}
.footer-action .v-btn{font-size: 14px}
.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before{opacity: 0;}
.before-enter {
    opacity: 0;
    transform: translateX(100px);
    transition: all 1s cubic-bezier(.41,.01,.57,1.61);
  }

  /* 
    If the element intersects with the viewport, the before-enter class is added.
  */
  .enter {
    opacity: 1;
    transform: translateX(0px);
  }
  .v-ripple__container {
    display:none !important;
}
.mytable th{font-size: 16px !important; font-weight: 500; color: #111827 !important}
.mytable td{font-size: 18px !important; font-weight: 700;}
.mytable.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{border-bottom: none !important}
.theme--light.v-list-item:focus::before{opacity: 0}
.mytable .v-data-table__wrapper table tbody tr{ border-radius: 16px; }
.mytable .v-data-table__wrapper table {border-collapse: separate; border-spacing: 0 20px !important;}
.mytable .v-data-table__wrapper table tbody td:first-child { 
  border-left: 2px solid #E5E7EB !important;
  border-right: 0 !important;
  border-top: 2px solid #E5E7EB !important;
  border-bottom: 2px solid #E5E7EB !important;
  border-radius: 24px 0 0 24px; padding:2px 10px;

}
.mytable .v-data-table__wrapper table tbody td:last-child{
  border-left: 0 solid #E5E7EB !important;
  border-right: 2px solid #E5E7EB  !important;
  border-top: 2px solid #E5E7EB !important;
  border-bottom: 2px solid #E5E7EB !important;
  border-radius: 0 24px 24px 0; padding:2px 10px;
}


.mytable .v-data-table__wrapper> table > tbody > tr:hover td:first-child { border-left: 2px solid #E5E7EB !important;
  border-right: 0 !important;
  border-top: 2px solid #E5E7EB !important;
  border-bottom: 2px solid #E5E7EB !important;
  border-radius: 24px 0 0 24px !important; padding:2px 10px;
}
.mytable .v-data-table__wrapper> table > tbody > tr:hover td:last-child{
  border-left: 0 solid #E5E7EB !important;
  border-right: 2px solid #E5E7EB  !important;
  border-top: 2px solid #E5E7EB !important;
  border-bottom: 2px solid #E5E7EB !important;
  border-radius: 0 24px 24px 0 !important; padding:2px 10px;

}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) { background: rgb(232, 239, 254) !important;}



.mytable .v-data-table__wrapper table tbody td{border-bottom: 2px solid #E5E7EB !important; border-top: 2px solid #E5E7EB !important;}
.mytable th.text-start.sortable{border-bottom: 0 !important;}


.custom-table-mlccchg  .v-data-table__wrapper table thead tr th{border-left: thin solid rgba(0, 0, 0, 0.12); padding: 0 5px !important;}
.custom-table-mlccchg  .v-data-table__wrapper table tbody tr th:first-child { border-left: none;}
.custom-table-mlccchg  .v-data-table__wrapper table thead tr th span { font-size: 18px;}
.custom-table-mlccchg  .v-data-table__wrapper table tbody tr td{border-left: thin solid rgba(0, 0, 0, 0.12); }
.custom-table-mlccchg  .v-data-table__wrapper table tbody tr td:first-child { border-left: none;}




/*TAB STYLE STARTS*/
 
.mytabs a.v-tab{background-color: #E8EFFE; padding:16px 22px; position:relative; text-transform: inherit; font-family: 'Satoshi-Bold'; font-size: 16px; letter-spacing: 0.8px;}

.mytabs .v-tabs-bar{height: auto}
.mytabs .v-tabs-slider-wrapper{height: 65% !important; top: 16%; opacity: 0.5; padding:0 10px;}
.mytabs .v-tabs-slider{border-radius: 12px; color: #fff; box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.08), 0px 4px 6px -4px rgba(16, 24, 40, 0.08);}
.mytabs .v-tab--active{color: #000 !important}
a.border-radiusbefore.v-tab{}
a.border-radiusbefore.v-tab{ border-radius:20px  0 0 20px}
a.border-radiusafter.v-tab{ border-radius:0px  20px  20px 0px}

.mytabs{
  .text-h4.text--primary{font-size: 32px !important; font-family: Satoshi-Bold !important}
  .v-list-item__title{font-size: 18px; font-weight: 500;}
}
/*TAB STYLE ENDS*/

/*image over effect START*/
.imgover .v-image__image {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    transition: 1s ease;
}

.imgleave .v-image__image {
    transition: 1s ease;
}

.before-enter {
    opacity: 0;
    transform: translateY(100px);
    transition: all 2s ease-out;
}

.enter {
    opacity: 1;
    transform: translateY(0px);
}
/*image over effect END*/
.custom-slider .v-carousel__controls{ background: #E8EFFE !important; border-radius: 10px;  }
.custom-slider .theme--dark.v-btn--active:hover::before, .custom-slider .theme--dark.v-btn--active::before{opacity: 0.9}
.custom-slider .theme--dark.v-btn:hover::before{opacity: 1}
.custom-slider .v-btn:before{opacity:0.4;}



    .slick-slide {
      margin: 0px 10px;
    }

     

    .slick-slide {
      transition: all ease-in-out .3s;
      opacity: .5;
    }
    
    .slick-active {
      opacity: .5;
    }

    .slick-current {
      opacity: 1;
    }
.right-menu .v-application a{color:#000 !important;}
.v-btn a.anchor-color{color: #0072FF;}

/* .gradient-button.anchorTag{ height: auto !important; border-radius: 28px; padding:0 !important; } */
.gradient-button.anchorTag{ border-radius: 28px; padding:0 !important; }
.gradient-button.anchorTag a{padding:12px 15px !important;}


</style>