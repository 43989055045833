import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    counter: 0,
    error_msg: '',
    success_msg: '',
    users_data: [],
    flight: {},
    homeSearch: {},
    airline: {},
    productId: ''
  },
  mutations: {
    increase(state, payload) {
      state.counter += payload.value;
    },
    // decrement(state) {
    //   state.counter -= 1;
    // }
    decrement(state, payload) {
      state.counter -= payload.value;
    },
    counter(state, payload) {
      state.counter = payload.value;
    },
    successMsg(state, payload) {
      state.success_msg = payload.value;
    },
    usersData(state, payload) {
      return state.users_data = payload;
    },
    errorMessage(state, payload) {
      return state.error_msg = payload;
    },
    setFlightData(state, data) {
      console.log("setFlightData", data)
      state.flight = { ...data }
    },
    setAirlineData(state, data) {
      console.log("setFlightData", data)
      state.airline = { ...data }
    },
    setHomeFligthSearch(state, data) {
      console.log("setHomeFligthSearch ", data)
      state.homeSearch = { ...data }
    },
    setproductId(state, data) {
      console.log("setHomeFligthSearch ", data)
      state.productId = data
    }
  },
  actions: {
  },
  getters: {
    getCounter(state) {
      return state.counter;
    },
    getUsersData(state) {
      return state.users_data;
    },
    getErrorMessage(state) {
      return state.error_msg;
    },
    getSuccessMsg(state) {
      return state.success_msg;
    },
    getFlightData(state) {
      console.log("getFlightData", state.flight)
      return state.flight
    },
    getAirlineData(state) {
      console.log("getAirlineData", state.airline)
      return state.airline
    },
    getHomeFligthSearch(state) {
      console.log("getHomeFligthSearch", state.homeSearch)
      return state.homeSearch
    },
    getProductId(state) {
      return state.productId
    }
  },
  modules: {
  }
})

